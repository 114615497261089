


































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import InsPage from '@/components/base/pc/InsPage.vue';
@Component({
  components: {
    SildeBanner: () => import('@/components/base/pc/InsBanner.vue'),
    InsPage
  }
})
export default class InsOrderList extends Vue {
  currentPage: number = 1;
  pagesize: number = 6;
  OrderListLength: any = '';
  OrderList:any[]=[];
  fullscreenLoading:boolean =false;
  getOrder () {
    let that = this;
    // this.$loading({
    //   lock: true,
    //   text: 'Loading',
    //   spinner: 'el-icon-loading',
    //   background: 'rgba(0, 0, 0, 0.7)'
    // });
    return that.$Api.order.getPageOrder(that.pagesize, that.currentPage).then((result) => {
      that.OrderList = result.Data;
      that.OrderListLength = result.TotalRecord;
      this.$HiddenLayer();
      // this.$loading({
      //   lock: true,
      //   text: 'Loading',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.7)'
      // }).close();
      // eslint-disable-next-line no-unused-expressions
    });
  }
  async created () {
    let that = this;
    this.getOrder();
  }
  ShowDetail (order) {
    // 新窗口打开路由
    let routeData = this.$router.push({
      path: '/account/orderDetail',
      name: 'orderDetail',
      params: { id: order.OrderId }
    });
  }

  // 支付打开页面
  PayNow (order) {
    // let routeData = this.$router.resolve({
    //   path: '/intimex/pay/' + order.PaymentMethod + '/',
    //   name: 'payment',
    //   params: { id: order.OrderId }
    // });
    window.open('/intimex/pay/' + order.PaymentMethod + (order.PaymentMethod === 'Stripe' ? '/' : '/IS/') + order.OrderId, '_blank');
  }
  OrderComment (order) {
    this.$router.push({
      path: '/account/orderComment',
      name: 'orderComment',
      params: { id: order.OrderId }
    });
  }
  currentChange (currentPage) {
    console.log(currentPage, 'currentPagecurrentPagecurrentPagecurrentPage');
    // 改变当前页
    this.currentPage = currentPage;
    this.getOrder();
  }
  // 点击上一页和下一页的时事件
  handleSizeChange (psize) {
    this.pagesize = psize;
  }
}
